import Moment from 'moment';
import String from '@/utils/string.js';

const Sm4PrivateKey = '30313233343536373839616263646566';//为后端密钥的Ascii码
const password= '1028.';

export { Sm4PrivateKey, password };

export default {
    moment: Moment,
    string: String
}