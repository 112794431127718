import Vue from 'vue'
//工具类导入
import Util from './utils/index.js';
Vue.prototype.$util = Util;
import App from './App.vue'
import router from './router'
import store from './store'

//获取OpenId
store.dispatch('user/setOpenId', Util.string.getUrlParam(window.location.search).openid);
Util.string.getUrlParam(window.location.search).medicalAssistant == '1' &&
store.dispatch('user/setMedicalAssistant', true);
// import { Toast } from 'vant';

import Meta from 'vue-meta'
Vue.use(Meta);

import less from 'less'
Vue.use(less);

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard);

// import Advertising from './components/Advertising/index.js'
// Vue.use(Advertising);
import {
    NavBar,
    Tabbar,
    TabbarItem,
    Icon ,

    Col, Row,
    Badge,
    Tag,

    Button,
    RadioGroup, Radio,
    Field,
    PasswordInput,
    NumberKeyboard,
    Uploader,
    Calendar,
    Form,

    Image,

	ActionSheet,
    Toast,
    Dialog,

    Area,
    Popup,
    Picker,
    DatetimePicker,
    Cell,
    CellGroup,
    Card,
    Lazyload,
    Loading,
		AddressEdit,
	Overlay,
	Step,
	Steps
} from 'vant';
Vue.use(Lazyload);
Vue.use(NavBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Icon);

Vue.use(Col);
Vue.use(Row);
Vue.use(Badge);
Vue.use(Tag);

Vue.use(Button);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Field);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Uploader);
Vue.use(Calendar);
Vue.use(Form);

Vue.use(Image);

Vue.use(ActionSheet);
Vue.use(Toast);
Vue.use(Dialog);

Vue.use(Area);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Card);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(AddressEdit);
Vue.use(Step);
Vue.use(Steps);

Vue.config.productionTip = false;


Vue.filter('filterCardNum', function (value) {
    return (value ? `${value.substring(0, 6)}****${value.substring(value.length - 6)}` : '');
});
Vue.filter('filterName', function (value) {
    let nameLength = value.length - 2;
    return (value ? `${value.substring(0, 1)}*${value.substring(value.length - nameLength)}` : '');
});
Vue.filter('filterIdCardNum', function (value) {
    return (value ? `${value.substring(0, 14)}****` : '');
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');