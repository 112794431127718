<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import { getToken } from './api/user.js'
export default {
  name: 'APP',
  metaInfo: {
    title: '云医院',
    meta: [{
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
    }]
  },
  mounted() { },
  // beforeCreate() {
  //   getToken().then(res => {
 
  //   })
  // }
}
</script>

<style>
.van-button {
  border-radius: 8px;
}

.van-badge {
  border: none;
}

.van-uploader__input-wrapper {
  width: 100%;
}
</style>
