import { sm4 } from "sm-crypto";
import { Sm4PrivateKey } from "@/utils/index.js";

/**
 * Created by zkx on 2019/7/5.
 */
export default {
    getUrlParam: (url) => {
        const search = url.split('#')[0].split('?')[1]
        if (!search) {
            return {}
        }
        return JSON.parse(
            '{"' +
            decodeURIComponent(search)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"')
                .replace(/\+/g, ' ') +
            '"}'
        )
    },
    //sm4加密
    sm4encryption: (str) => {
        return sm4.encrypt(str, Sm4PrivateKey); // 加密结果
    },
    sm4decryptData: (str) => {
        return sm4.decrypt(str, Sm4PrivateKey); //解密
    },
}