import Axios from 'axios'
import Vue from 'vue'
import {userLogIn} from '@/api/user.js'
import stringUtil from '@/utils/string.js'
import store from "@/store";

// create an axios instance
const axios = Axios.create({
    // baseURL: 'https://xfgf.knjs.com.cn/CloudHospital', // url = base url + request url
    // baseURL: 'https://cloudhospitalapi.knjs.net', // url = base url + request url
    baseURL: process.env.VUE_APP_API_BASE_URL, // url = base url + request url
    // baseURL: 'https://cloudhospitalapipro.knjs.net', // url = base url + request url正式库
    withCredentials: false, // send cookies when cross-domain requests
    // timeout: 0 ,// request timeout

})

// 添加请求拦截器
axios.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        // if (store.getters.token) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        // config.headers['X-Token'] = store.getters.token;
        config.headers['hosId'] = '1'
        // config.headers['x-access-token'] = '123';
        config.headers['token'] = localStorage.getItem('access_token')
        config.headers['Access-Control-Allow-Origin'] = '*'
        config.headers['mode'] = 'no-cors'
        // console.log(config)
        // }
        return config
    },
    error => {
        // 对请求错误做些什么
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
axios.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */

    response => {
        // 对响应数据做点什么
        // const res = stringUtil.sm4decryptData(response.data);
        const res = response.data;
        if(res.ResultData){
            try {
                res.resultData = JSON.parse(stringUtil.sm4decryptData(res.ResultData || res.resultData));
            }catch {
                res.resultData = stringUtil.sm4decryptData(res.ResultData || res.resultData);
            }
            process.env.NODE_ENV === 'development' && console.warn(res.resultData);
        }
        res.resultStatus = res.ResultStatus || res.resultStatus;
        // if the custom code is not 20000, it is judged as an error.
        if (res.resultStatus !== 200) {
            if (res.resultStatus == 301) {
                Vue.prototype.$toast(res.msg || "未查询到数据")
            } else {
                //请求失败
                Vue.prototype.$dialog.alert({
                    message: (res.msg.indexOf('请重新上传身份证正面照片') != -1 ? res.msg + '或使用手动填写' : res.msg) || 'error',//提示内容
                    confirmButtonText: (res.msg.indexOf("当前账户没有默认账户") == "-1" || res.msg.indexOf("当前默认账户无效") == "-1") ? "确认" : "立即设置",
                    // duration: 5 * 1000 //自动关闭的延时，单位秒，不关闭可以写 0
                }).then(() => {
                    // on confirm
                    if (res.msg.indexOf("当前账户没有默认账户") != "-1" || res.msg.indexOf("当前默认账户无效") != "-1") {
                        window.location.replace(window.location.origin + window.location.search + "#/accountJudgment/0/0/0")
                    }
                })
                return Promise.reject(res.msg || 'error')
            }
        } else {
            //请求成功
            // if(res.msg) {
            // 	Vue.prototype.$toast(res.msg);
            // }
            return res.resultData
        }
    },
    error => {
        if (error.message.includes(401)) {
            userLogIn(localStorage.getItem('userPhoneNumber'), store.getters.openId).then(
                (result) => {
                    localStorage.setItem('access_token', result.token)
                    localStorage.setItem('access_refresh', result.refresh)
                }, () => {});
        }
        // 对响应错误做点什么
        Vue.prototype.$dialog.alert({
            message: error.message,//提示内容
            // duration: 5 * 1000 //自动关闭的延时，单位秒，不关闭可以写 0
        })
        return Promise.reject(error)
    }
)

export const axiosForLogistics = Axios.create({
    baseURL: process.env.VUE_APP_API_LOGISTICS_URL, // url = base url + request url
    // baseURL: 'https://cloudhospitalapipro.knjs.net', // url = base url + request url正式库
    withCredentials: false, // send cookies when cross-domain requests
    // timeout: 0 ,// request timeout

})

// 添加请求拦截器
axiosForLogistics.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        // if (store.getters.token) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        // config.headers['X-Token'] = store.getters.token;
        config.headers['Authorization'] = localStorage.getItem("logistics_token") || ''
        config.headers['x-access-token'] = '123'
        config.headers['Access-Control-Allow-Origin'] = '*'
        config.headers['mode'] = 'no-cors'
        // console.log(config)
        //   // }
        return config
    },
    error => {
        // 对请求错误做些什么
        // console.log(error); // for debug
        return Promise.reject(error)
    }
)

// response interceptor
axiosForLogistics.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */

    response => {
        // 对响应数据做点什么
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.resultStatus !== '200') {
            return Promise.reject(res || 'error')
        } else {
            //请求成功
            // if(res.msg) {
            // 	Vue.prototype.$toast(res.msg);
            // }
            return res.resultData
        }
    },
    error => {
        // 对响应错误做点什么
        // console.log('err' + error) // for debug
        Vue.prototype.$dialog.alert({
            message: error.message,//提示内容
            // duration: 5 * 1000 //自动关闭的延时，单位秒，不关闭可以写 0
        })
        return Promise.reject(error)
    }
)

export default axios